<template>
    <div class="modal-newsletter-preferences" v-show="modalNewsletterPreferences.show">
      <div class="header">
        <img :src="logoAtlas" alt="Logo">
        <p>Configuração de preferências de Newsletter</p>
        <div class="box-close" @click="openModal">
          <span class="close" v-show='closeButon'></span>
        </div>
      </div>

      <div class="content">
        <div class="tabs-nav">
          <ul>
            <li>
              <div class="box-text">
                <p>Suas Preferências</p>
              </div>
              <div class="box-accepts"></div>
            </li>
            <li>
              <div class="box-text"  @click="activeTab = 'publicity'" :class="[ activeTab === 'publicity' ?'active' : '']">
                <p>Receber Publicidade</p>
              </div>
              <div class="box-accepts">
                <div class="accept">
                  <input class="option" type="radio"  value="1" name="receive_newsletter" id="receive_newsletter_on" v-model.number="receive_newsletter" />
                  <label for="receive_newsletter_on">Sim</label>
                </div>
                <div class="accept">
                  <input class="option" type="radio"  value="0" name="receive_newsletter" id="receive_newsletter_off" v-model.number="receive_newsletter" />
                  <label for="receive_newsletter_off">Não</label>
                </div>
              </div>
              <div class="box-accepts"></div>
            </li>
            <li>
              <p class="message"></p>
            <div class="box-buttons">
                <button @click="acceptAll">Receber todos</button>
                <button @click="rejectAll">Cancelar todos</button>
            </div>
            </li>
          </ul>
        </div>
        <div class="tabs">
          <div class="tab" :class="[ activeTab === 'notification' ?'active' : '']">
            <h3 class="teste ">NOTIFICAÇÕES</h3>
              <p>As notificações enviadas pela Atlas S/A são alertas de pedidos, solicitações, interações no Portal Atlas e Meus Pedidos.
                  Respeitamos a sua decisão de escolha ao optar por receber as notificações dos nossos sistemas e você pode optar por configurar suas preferências de recebimento de notificações a qualquer momento através do Meu Perfil.
              </p>
          </div>
          <div class="tab" :class="[ activeTab === 'publicity' ?'active' : '']">
            <h3 class="teste ">PUBLICIDADE</h3>
              <p>Ao receber as publicidades enviadas pela Atlas S/A você ficará por dentro de lançamentos e novidades dos nossos produtos, informativos, promoções e muito mais.
                  Respeitamos a sua decisão de escolha ao optar por receber nosso conteúdo e você pode optar por configurar suas preferências de recebimento de publicidade a qualquer momento através do Meu Perfil.
              </p>
          </div>
        </div>
      </div>
      <div class="footer">
        <button v-on:click="agreementSend">Confirmar minhas escolhas</button>
      </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: "modal-newsletter-preferences",
    props: {
        logoAtlas: String,
        receiveNewsletter: String,
        agreementNewsletter: String,
    },
    data() {
        return {
            closeButon: true,
            activeTab: 'publicity',
            receive_newsletter: 0,
            agreement_newsletter: 0,
        }
    },
    mounted() {
        this.receive_newsletter = parseInt(this.receiveNewsletter)
        this.agreement_newsletter = parseInt(this.agreementNewsletter)

        if (parseInt(this.agreementNewsletter) == 0) {
            this.closeButon = false
            this.openModal()
        }
    },
    computed: mapState({
        modalNewsletterPreferences: state => state.modalNewsletterPreferences

    }),
    methods: {
        openModal() {
            this.$store.dispatch('modalNewsletterPreferences/openModal');
        },
        selectTab(tab) {
            this.activeTab = tab;
        },
        acceptAll() {
            this.receive_newsletter = 1
        },
        rejectAll() {
            this.receive_newsletter = 0
        },
        async agreementSend() {
            let newsletter = {}
            newsletter['receive_newsletter'] = this.receive_newsletter;

            try {
                await this.axios.post(_ROOT_PATH + '/clube-powertech/agreement-newsletter', newsletter)
                this.openModal()
                document.location.reload()
            } catch (error) {
                console.log(error)
            }
        },
    },
}
</script>
