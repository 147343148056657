<template>
    <div class="shadow">
        <div class="modal-background p-5">
            <div class="title-container mb-3">
                <span class="fz-medium px-2 title">{{title}}</span>
            </div>
            <div class="content-container">
                <slot></slot>
            </div>
            <div class="button-container mt-3">
                <button 
                    v-if="secondarybuttontext" 
                    @click="secondarybuttoncallback "
                    class="text-white bg-dark-gray text-center border-0 rounded-pill py-1 px-3 fz-medium text-uppercase btn btn-primary"
                >
                    {{secondarybuttontext}}
                </button>
                <button 
                    @click="primarybuttoncallback" 
                    class="text-white bg-secondary text-center border-0 rounded-pill py-1 px-3 fz-medium text-uppercase btn btn-secondary"
                >
                    {{primarybuttontext}}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'modal-aviso',
        props: {
            title: {
                type: String,
                required: true
            },
            primarybuttontext: {
                type: String,
                required: true
            },
            primarybuttoncallback: {
                type: Function,
                required: true
            },
            secondarybuttontext: {
                type: String,
            },
            secondarybuttoncallback: {
                type: Function,
            },
        }
    }
</script>
<style scoped lang="scss">
.shadow{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-background{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    max-width: 500px;
    background-color: #fff;
    flex-direction: column;
    @media screen and (max-width: 500px) {
        width: 100%;
    }
}
.title-container{
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    
    &:before{
        content: '';
        top: 50%;
        display: block;
        width: 100%;
        height: 1px;
        background-color: #252642;
        position: absolute;
    }
}
.title{
    background-color: #fff;
    z-index: 10;
    color: #252642;
    font-size: 36px;
    font-weight: 700;
}
.content-container{
    color: #999999;
}
.text-blue{
    color: #15153B;
    font-weight: 700;
}
p span{
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    background-color: #15153B;
    font-weight: 700;
    margin-right: 10px;
}
.button-container{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 30px;
    button {
        flex-grow: 1;
    }
    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
}
</style>