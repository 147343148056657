<template>
     <Modal-aviso title="PARABÉNS!" primarybuttontext="Ok" :primarybuttoncallback="closeModal" v-if="showModal">
        <div v-if="approved">
            <p>
                O seu cadastro foi aprovado para ganhar a garantia adicional!
            </p>
            <p>
                Continue aproveitando o Clube PowerTech, acompanhe os videos exclusivos e cadastre novos produtos!
            </p>
        </div>
        <div v-else>
            <p>
                Você concluiu a primeira etapa para ganhar a garantia adicional!
            </p>
            <p>
                Agora é só aguardar a validação do seu cadastro.
            </p>
            <p>
                Avisaremos você por e-mail assim que o cadastro for aprovado!
            </p>
        </div>
    </Modal-aviso>
</template>
<script>
    export default {
        name: 'modal-aviso-concluido',
        data: function () {
            return {
                showModal: false,
                approved: false
            };
        }, 
        methods: {
            closeModal: function () {
                this.showModal = false
                window.location.reload();
            },
        }
    }
</script>