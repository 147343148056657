<script>
    import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
    import "flatpickr/dist/flatpickr.css";
    import Swal from 'sweetalert2';
    export default {
        name:'Product-clubepowertech',
        props: [
            'productList'
        ],
        data() {
            return {
                data:{},
                flatConfig: {
                    locale: Portuguese,
                    dateFormat: 'd/m/Y',
                    "disable": [ this.disabledAfterToday ]
                },
                errorSerial: [],
                productCount: [''],
                newProduct: '',
                productSubmitedCount: 0,
                defaults: {
                    buy_date:'',
                    store:'',
                    fiscal_archive:'',
                    fiscal_number:'',
                    newsletter: true,
                    privacy_policy:'',
                    success: false,
                    error: false,
                },
                products: this.productList,
                city:[],
                classes: {},
                loading: false,
            };
        },
        mounted() {
           this.clearForm()
        },
        methods: {
            clearForm() {
                this.data = Object.assign({}, this.defaults);
            },
            async onSubmit (event) {
                if (this.loading){
                    return;
                }
                this.classes = {}
                this.loading = true;

                var formData = new FormData(this.$refs.form),
                url = this.$refs.form.getAttribute("action");
                var vm = this
                await this.axios
                    .post(url, formData,{
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if(response.data.success == true){
                            vm.clearForm()
                            vm.data.warrantyExpired = response.data.warrantyExpired
                            vm.data.availableWarranty = response.data.availableWarranty
                            vm.data.productSubmitedCount = response.data.productSubmitedCount
                        }else{
                            vm.errorSerial = response.data.serial_numbers
                            if (response.data.message) {
                                Swal.fire({
                                    title: 'Oops!',
                                    text: response.data.message,
                                    type: 'error',
                                    confirmButtonColor: '#d63030',
                                    confirmButtonText: 'Ok!'
                                });
                            }
                        }
                    })
                    .catch( error => {
                        if(error.response.status !== 422 || !error.response.data.errors ) {
                            Swal.fire({
                                html: '<div><span class="modal-profile-title">Falha ao enviar o formulário!</span><span class="modal-profile-text">Tivemos um problema ao enviar o formulário, tente novamente!</span></div>',
                                type: 'error',
                                confirmButtonColor: '#d63030',
                                confirmButtonText: 'Ok',
                                width: 550,
                                padding: '40px',
                                customClass: {
                                    confirmButton: 'modal-profile-button'
                                }
                            });
                        }
                        vm.classes = error.response.data.errors || {}
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            addProduct() {
                this.productCount.push('Novo' + this.productCount.length +1);
            },
            disabledAfterToday(date) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return date > today
            },
            openModalVideo: function () {
                this.showModal = true
            },
            closeModalVideo: function () {
                this.showModal = false
            },
        },
    };
</script>