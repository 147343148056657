<template>
    <Modal-aviso title="AVISO!" primarybuttontext="Ok" :primarybuttoncallback="closeModal" v-if="showModal"> 
        <p>
            Prezados clientes,
        </p>
        <p>
            Devido aos últimos eventos climáticos que assolam o estado do Rio Grande do Sul onde está situada a nossa Matriz na unidade de Esteio/RS nossos serviços de comunicação e rede também foram afetados.
        </p>
        <p>
            No momento estamos trabalhando com equipe reduzida e atendendo demandas prioritárias, sendo assim, os contatos via SAC podem demorar para serem atendidos.
        </p>
        <p>
            Contamos com a compreensão de todos neste momento tão difícil que estamos enfrentando!
        </p>
   </Modal-aviso>
</template>
<script>
    export default {
        name: 'modal-aviso-equipe-reduzida',
        props:[
            "openModal"
        ],
        data: function () {
            return {
                showModal: false,
            };
        },
        watch: {
            openModal: function (val) {
                this.showModal = val
            },
        },
        methods: {
            closeModal: function () {
                this.showModal = false
            },
        },
        mounted() {
            this.showModal = this.openModal
        }
    }
</script>