<template>
    <div class="mb-2">
        <div
            v-show="canPlay"
            id="video-player"
        ></div>
        <vue-element-loading :active="!canPlay" spinner="bar-fade-scale"/>
    </div>
</template>
<script>
    import Vimeo from '@vimeo/player';
    export default{
        name: 'vimeo-player',
        props: {
            vimeoId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                totalTime: 0,
                ended: false,
                watched: false,
                duration: 0,
                canPlay: false,
            }
        },
        mounted() {
            const options = {
                id: this.vimeoId,
                responsive: true,
            };

            const vimeoPlayer = new Vimeo('video-player', options);
            vimeoPlayer.getDuration().then((duration) =>{
                this.duration = duration;
            });

            vimeoPlayer.ready().then(() => {
                this.canPlay = true;
            });

            vimeoPlayer.on('ended', () => {
                this.totalTime = 0;
                vimeoPlayer.getPlayed().then((playedSegments) =>{
                    
                    playedSegments.forEach((segment) => {
                        this.totalTime +=  segment[1] - segment[0];
                    });

                    this.watched = this.totalTime > (this.duration * 0.8);
                    
                    if(this.watched) {
                        this.$emit('watched')
                    }

                });
            });
        }   
    }
</script>
<style scoped lang="scss">
#video-player {
  position: relative;
  padding-bottom: 56.25%; 
  height: 0;
  overflow: hidden;
}

#video-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>