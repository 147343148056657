<template>
    <div class="mb-2">
        <div v-show="canPlay">
            <video
                id="video-player"
                class="video-js vjs-default-skin"
                controls
                preload="auto"
                ref="videoElement"

            ></video>
        </div>
        <vue-element-loading :active="!canPlay" spinner="bar-fade-scale"/>
    </div>
</template>
<script>
    import videojs from 'video.js';
    import 'video.js/dist/video-js.css';
    import 'videojs-youtube/dist/Youtube';
    export default{
        name: 'youtube-player',
        props: {
            videoUrl: {
                type: String,
                required: true
            }
        },
        data: function () {
            return {
                playedSegments: [],
                totalTime: 0,
                ended: false,
                watched: false,
                canPlay: false,
            }
        },
        methods: {
            
        },
        mounted() {
            this.player = videojs('video-player', {
                techOrder: ['youtube'],
                sources: [{
                    type: 'video/youtube',
                        src: this.videoUrl
                }]
            });

            this.player.on('ready', ()=> {
                this.canPlay = true;
            });
            
            this.player.on('timeupdate', ()=> {
                if(this.ended == false && this.watched == false){
                    if(this.totalTime < ( this.player.duration() * 0.8 )){
                        this.totalTime += 0.250
                    }
                    else {
                        this.watched = true;
                    }
                }
            });
            this.player.on('ended', ()=> {
                this.ended = true;
                if(this.watched) {
                    this.$emit('watched')
                }
                this.player.el().classList.add('esconder-sugestoes');
            });
            this.player.on('play', ()=>{
                this.ended = false;
            });
        },
        beforeDestroy() {
            this.player.dispose();
        } 
    }
</script>
<style scoped lang="scss">
    #video-player{
        width: 100%;
        padding-bottom: 56.25%;
        margin-bottom: 15px;
    }
</style>
