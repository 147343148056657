<script>
    export default {
        name:'Profile-clubepowertech',
        props: [
            'user',
            'stateList',
            'unseenVideo'
        ],
        data() {
            return {
                postcode: '',
                success: false,
                error: false,
                data: {},
                states: this.stateList,
                city:[],
                classes: {},
                loading: false,
                'mask':'###.###.###-##',
                select2Settings: {
                    placeholder: {
                        id: 0, // the value of the option
                        text: 'Selecione'
                    },
                    templateSelection: function (data) {
                        if (data.id == '0' || data.id == '' ) { // adjust for custom placeholder values
                            return 'Selecione';
                        }

                        return data.text;
                    }
                },
                showModalVideo: false,
                warrantyExpired: false,
                showModalDate: false,

            }
        },
        mounted() {
            this.data = this.user;
            var vm = this
            if(this.data.state  != null){
                //this.data.state = this.data.state
                this.getCities();
            }

            if(this.data.person_type == 'juridica'){
                this.changeLabelText('CNPJ')
                this.data.mask = '##.###.###/####-##';
            }

            if(this.data.person_type == 'fisica'){
                this.changeLabelText('CPF')
                this.data.mask = '###.###.###-##';
            }

            if(this.unseenVideo.length > 0) {
                this.showModalVideo = true;

                if(this.unseenVideo.length == 1) {
                  if(this.unseenVideo[0].warranty_status_id == 1) {
                    this.warrantyExpired = true;
                    this.showModalVideo  = false;
                  }
                }

            }
        },
        computed: {
          cepValue() {
            return this.data.postcode;
          },
        },
        watch: {
          cepValue(val) {
            this.getPostCode(val);
          }
        },
        methods: {
            changeLabelText(text){
                this.data.documentLabelText = text
            },
            async getCities() {
                await this.axios.post(APP_URL + '/load-cities',{
                state: this.data.state
                }).then(function(response){
                    this.city = response.data.cities;

                }.bind(this));
            },

            async getPostCode(value) {
              try {
                const CEP = value.replace(/[^\w\s]/gi, '');

                if (CEP.length !== 8) {
                  return false;
                }

                const response = await this.axios.get(`https://viacep.com.br/ws/${CEP}/json`, {
                  transformRequest: (data, headers) => {
                    delete headers.common['X-CSRF-Token']
                  }
                });

                if (response.data.erro) {
                  throw 'error';
                }

                this.data.address  = response.data.logradouro;
                this.data.district = response.data.bairro;
                this.data.city     = response.data.localidade;
                this.data.state    = response.data.uf;

                this.selectState(this.data)
                this.selectCity(this.data)
              } catch (error) {
                console.log('Ocorreu um erro ao localizar');
              }
            },
            async onSubmit (event) {
                if (this.loading){
                    return;
                }
                this.classes = {}
                this.loading = true;

                var formData = new FormData(this.$refs.form),
                    url = this.$refs.form.getAttribute("action");
                this.success = false
                this.error = false

                var vm = this
                await this.axios
                    .post(url, formData)
                    .then(response => {
                        if(response.data.success == true){
                            vm.success = true
                        }else{
                            vm.error = true
                        }

                    })
                    .catch( error => {
                        vm.classes = error.response.data.errors || {}
                        vm.error = true
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            onChangeSelectCity({id, text}) {
                this.data.city = id;
            },
            onChangeSelectState({id, text}) {
                this.data.state = id;
                this.getCities();
            },
            closeModalVideo: function () {
                this.showModalVideo = false
            },
            selectState(data) {
              const elemState = $('#state');
              let state = elemState.find("option:contains('" + data.state + "')").val();
              this.data.state = state;
            },
            async selectCity(data) {
              await this.getCities();
              const elemCity = $('#city');

              elemCity.find('option').each(function() {
                const elemOption = $(this);

                if (elemOption.text() === data.city) {
                  elemCity.val(elemOption.val()).trigger('change');

                  return false;
                }
              });
            },
        },
    };
</script>