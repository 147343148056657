<script>
    export default {
        name: 'Find-assistance',
        data() {
            return {
                data:{},
                cityList:[],
                assistances:[],
                loading: false,
                defaults: {
                    'city':'',
                    'state':'',
                    'assistances':[{
                        'name': '',
                        'address_number': '',
                        'district': '',
                        'city': '',
                        'state': '',
                        'postcode': '',
                        'phone': '',
                    }]
                }
            };
        },
        mounted() {
            var vm = this;

            $('#category').on('change', function (ev, args) {
                if (!(args && "ignore" in args)) {
                    vm.data.category = $(this).val();
                }
            });

            $('#city').on('change', function (ev, args) {
                if (!(args && "ignore" in args)) {
                    vm.data.city = $(this).val();
                }
            });

            $('#state').on('change', function (ev, args) {
                if (!(args && "ignore" in args)) {
                    vm.data.state = $(this).val();
                    vm.getCities();
                }
            });
        },
        methods: {
            async getCities() {
                if (this.data.state == '' || typeof this.data.state == 'undefined'){
                    return;
                }

                await this.axios.post(APP_URL + '/assistencia/onde-encontrar-cidades', {
                        state: this.data.state,
                    }).then(function(response){
                        this.cityList = response.data.cities;
                    }.bind(this));
            },
            async onSubmit () {
                if (this.loading){
                    return;
                }

                this.loading = true;

                let formData = new FormData(this.$refs.form);
                const url = this.$refs.form.getAttribute("action");

                var vm = this;

                await this.axios.post(url, formData)
                    .then((response) => {
                        vm.assistances = response.data.result;
                    })
                    .catch( error => {
                        vm.classes = error.response.data.errors || {};
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
        },
    };
</script>