<script>
    export default {
        name: 'meus-produtos-lista',
        data: function () {
            return {
                showModalVideo: false,
                showModalDate: false,
                title:'',
                videos:'',
                videoType:'',
                video_id:'',
                product_id:'',
            };
        },
        methods: {
            openModalVideo: function (title, video, videoType, product_id, video_id, user_id) {
                this.title = title
                this.videos = video
                this.videoType = videoType
                this.product_id = product_id
                this.video_id = video_id
                this.user_id = user_id
                this.showModalVideo = true
            },
            closeModalVideo: function () {
                this.showModalVideo = false
            },
            openModalCongrats: function (approved) {
                this.$refs.modalconcluido.approved = approved;
                this.$refs.modalconcluido.showModal = true;
            },
           openModalExpired: function () {
              console.log('aqui meu bem');
              this.$refs.modalExpirado.showModal = true
              this.$refs.modalExpirado.openModal = true;
           }
        },
    }
</script>