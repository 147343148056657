<script>
export default {
    name: "button-modal-newsletter-preferences",
    methods: {
        showModalNewsletter() {
            this.$store.dispatch('modalNewsletterPreferences/openModal');
        },
    },
}
</script>
