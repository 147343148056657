<template>
    <a href="#" @click.prevent="showModalNewsletter">Configurações de Preferências de email</a>
</template>

<script>
export default {
    name: "link-modal-newsletter-preferences",
    methods: {
        showModalNewsletter() {
            this.$store.dispatch('modalNewsletterPreferences/openModal');
        },
    },
}
</script>
