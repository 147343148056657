<script>
    export default {
        name: 'Newsletter',
        data: function () {
            return {
                newsData: {},
                check: "",
                newsClasses: {},
                loading: false,
                sendError: false,
                sendSuccess: false,
                defaults: {
                    email: '',
                    newsletter_option_id: '',
                    privacy_policy: 0
                }
            };
        },

        mounted: function () {
            this.clearForm();
            var vm = this
            $('#newsletter_option_id').on('change', function (ev, args) {
                if (!(args && "ignore" in args)) {
                    vm.newsData.newsletter_option_id = $(this).val()
                }
            });
        },

        methods: {
            clearForm() {
                this.newsData = Object.assign({}, this.defaults);
                this.sendError = false
                this.sendSuccess = false
                this.newsClasses= {}
            },

            onSubmit: function (event) {
                event.preventDefault();
                if (this.loading){
                    return;
                }

                this.loading = true;

                var formData = new FormData(this.$refs.form),
                    url = this.$refs.form.getAttribute("action");

                this.sendError = false
                this.sendSuccess = false
                var vm = this
                this.axios
                    .post(url, formData)
                    .then(response => {
                        if(response.data.success == true){
                            $('#newsletter_option_id').val('').trigger('change')
                            vm.clearForm()
                            vm.sendSuccess = true
                        }
                        if(response.data.success == false){
                            vm.sendError = true
                        }

                    })
                    .catch(error => {
                        vm.newsClasses = error.response.data.errors || {}
                        this.sendError = true
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    };
</script>