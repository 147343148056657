import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueAxios from "vue-axios";
import VueTheMask from "vue-the-mask";
import VueSimpleSuggest from 'vue-simple-suggest';
import VueElementLoading from 'vue-element-loading';
import { upperFirst, camelCase } from "lodash";
import $ from 'jquery';
import store from "./store";
import VueFlatPickr from "vue-flatpickr-component";
import Select2 from 'v-select2-component';

window.$ = window.jQuery = $;
require('bootstrap/dist/js/bootstrap.min');

require('select2/dist/js/select2.full');

require('slick-carousel/slick/slick');

Vue.use(Vuex);
Vue.use(VueAxios, axios);
Vue.use(VueTheMask);
Vue.use(VueFlatPickr);
Vue.component('Select2', Select2);
Vue.component('VueSimpleSuggest', VueSimpleSuggest);
Vue.component('VueElementLoading', VueElementLoading);


Vue.axios.defaults.headers.common["X-CSRF-Token"] = $(
    'meta[name="csrf-token"]'
).attr("content");

const requireComponent = require.context("./elements", true, /[\w-]+\.vue$/);

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName);
    const filenameSplit = fileName.split("/");
    const componentName = upperFirst(
        camelCase(filenameSplit[filenameSplit.length - 1].replace(/\.\w+$/, ""))
    );
    Vue.component(componentName, componentConfig.default || componentConfig);
});

const app = new Vue({ el: "#app", store});


var lastScrollTop = 0;

window.addEventListener("scroll", function(){  
    var st = window.pageYOffset || document.documentElement.scrollTop;  
    if (st > 200){
        $(".menu-topo-fixado").css('top', '0');
        $('.menu-white').removeClass('show');
    } else {
        $(".menu-topo-fixado").css('top', '-100%');
        $('.menu-primary').removeClass('show');
    }
    lastScrollTop = st;
}, false);

$('a.submenu').on('click', function(e) {
    e.preventDefault();
});

$('.trigger-submenu-primary').on('click', function(e) {
    e.preventDefault();
    if ($('.menu-primary').hasClass('show')) {
        $('.menu-primary').removeClass('show');
    } else {
        $('.menu-primary').addClass('show');
    }
});

$('.trigger-submenu-white').on('click', function(e) {
    e.preventDefault();
    if ($('.menu-white').hasClass('show')) {
        $('.menu-white').removeClass('show');
    } else {
        $('.menu-white').addClass('show');
    }
});

$('.trigger-submenu').on('click', function(e) {
    e.preventDefault();
    var submenu = $(this).next('.submenu-container');
    if (submenu.hasClass('show')) {
        $('.submenu-container').removeClass('show');
    } else {
        $('.submenu-container').removeClass('show');
        submenu.addClass('show');
    }
});

$('select:not(.flatpickr-monthDropdown-months)').select2();

$('.file-btn').on('click', function() {
    $('input[type="file"]').trigger('click');
});

$('input[type="file"]').on('change', function() {
    var fileName = $(this)[0].files[0].name;
    $('.file-label').text(fileName);
});

$('.trigger-menu-mobile').on('click', function(e) {
    e.preventDefault();
    var submenu = $('.mobile-menu .position-fixed');
    if (submenu.hasClass('d-none')) {
        submenu.removeClass('d-none');
        $(this).addClass('active');
    } else {
        submenu.addClass('d-none');
        $(this).removeClass('active');
    }
});

$('.slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    fade: true,
    infinite: false,
    asNavFor: '.slider-nav',
    responsive: [
        {
            breakpoint: 768,
            settings: {
                dots: true
            }
        }
    ]
});

$('.slider-nav').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    vertical: true,
    arrows: true,
    asNavFor: '.slider-for',
    dots: false,
    infinite: false,
    focusOnSelect: true
});