<template>
    <div :id="'product_' + counter" class="my-5 my-lg-2 product">
        <div id="products" class="row">
            <div class="col-12 mb-3 mb-lg-2 col-lg-4  col-xl-5 d-flex justify-content-center flex-column" :class="[errorCheck('product', counter)?'error':'']">
                <label for="" class="text-primary d-block font-weight-500 label-product-input">Referência do produto<span class="text-secondary">*</span></label>

                <Select2
                    name="product[]"
                    :id="'product_'+counter"
                    :class="'product_ref'"
                    v-model="data.product"
                    :options="products"
                    @select="onchangeSelect($event)"
                    :settings="select2Settings"
                />
                <div class="warning-field">
                    <template inline-template v-if="errorCheck('product', counter)">
                        <p class="text-secondary mb-1 fz-extra-small"><i class="bi bi-exclamation-circle-fill"></i> Campo de preenchimento obrigatório</p>
                    </template>
                </div>
            </div>
            <div class="col-12 mb-3 mb-lg-2 col-lg-4 col-xl-3 d-flex justify-content-center flex-column" :class="[errorCheck('serial_number', counter) || isErrorSerial(data.serial_number) ? 'error' : '']">
                <label for="" class="text-primary d-block font-weight-500 label-product-input">Número de série do produto<span class="text-secondary">*</span></label>
                <input v-model='data.serial_number' name="serial_number[]" :id="'serial_number_' + counter" type="text" class="d-block w-100 bg-white rounded-pill py-2 px-3 border-1 text-dark-gray" v-mask="'XXXXXXXXXXXXXX'">
                <div class="warning-field">
                    <template inline-template v-if="errorCheck('serial_number', counter)">
                        <p v-for="error of getCustomError('serial_number', counter)" class="text-secondary mb-1 fz-extra-small"><i class="bi bi-exclamation-circle-fill"></i> Campo número de série do produto é obrigatório</p>
                    </template>
                    <template inline-template v-for="error in errorSerial">
                        <p v-if="error == data.serial_number" class="text-secondary mb-1 fz-extra-small"><i class="bi bi-exclamation-circle-fill"></i> Número de série inválido</p>
                    </template>
                </div>
            </div>
            <div class="col-12 col-lg-3 order-2 foto">
                <img :src="data.photo" alt="" class="mw-100 d-block mx-auto mb-0">
            </div>
            <div class="col-12 col-lg-1 order-2 d-flex justify-content-center">
                <button type="button" class="bg-secondary delete-button mt-3 m-lg-auto" v-if="counter >0" @click="removeProduct(counter)"><i class="bi bi-trash"></i></button>
            </div>
        </div>
        <hr class="d-block d-lg-none mt-5">
    </div>
</template>
<script>
export default {
    name:'Product-item',
    props: [
        'productList',
        'counter',
        'classesError',
        'errorSerial'
    ],
    data() {
        return {
            data:{
                product: 0,
                serial_number:'',
                photo: '',
            },
            errorSerial: this.errorSerial ?? [],
            defaults: {
                product: 0,
                serial_number: '',
                photo: '',
            },
            products: this.productList,
            classes: this.classesError,
            select2Settings: {
                placeholder: {
                    id: 0,
                    text: 'Selecione'
                }
            }
        };
    },
    watch: {
        classesError: function(newVal, oldVal) { // watch it
            this.classes = newVal;
        }
    },
    mounted() {
        this.clearForm()
    },
    methods: {
        clearForm() {
            this.data = Object.assign({}, this.defaults);
        },
        async getPhoto(id) {

            let vm = this

            if(this.data.product == '0'){
                return
            }

            await this.axios.post(APP_URL + '/clube-powertech/cadastrar-produto-photo',{
                product_id: this.data.product
            }).then(function(response){
                vm.data.photo = response.data.photo;
            }.bind(this));
        },
        removeProduct(key) {
            const item = document.getElementById('product_'+key)
            item.remove();
        },
        errorCheck(field, index) {

            if (Object.keys(this.classes).length == 0) {
                return false
            }

            const fieldName = field + '.' + index;

            if (!this.classes.hasOwnProperty(fieldName)) {
                return false;
            }

            return this.classes.hasOwnProperty(fieldName);
        },
        isErrorSerial(serialNumber) {
            return this.errorSerial.includes(serialNumber);
        },
        getCustomError(field, index) {

             if (Object.keys(this.classes).length == 0) {
                 return []
             }

             const fieldName = field + '.' + index;

             if (!this.classes.hasOwnProperty(fieldName)) {
                 return [];
             }

             return this.classes[fieldName]
        },
        onchangeSelect({id, text}){
            this.data.product = id
            this.getPhoto()
            console.log(this.data)
        }
    },
};
</script>