<template>
    <div>
        <div class="bg-primary">
            <div class="container p-5">
                <div class="row font-family-barlow">
                    <div class="col-md-5 col-12 d-flex align-items-center">
                        <i class="bi bi-geo-alt-fill text-secondary" style="font-size:140px;"></i>
                        <h3 class="text-white fz-large text-uppercase font-weight-bold">Onde Encontrar</h3>
                    </div>
                    <div class="col-12 col-md-7 fz-small">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <label for="" class="text-white d-block">Estado</label>
                                <select id="where-to-find-state" class="d-block w-100 bg-white rounded-pill py-2 px-3 border-1 text-dark-gray">
                                    <option value="">Selecione</option>   
                                    <option v-for="(state, index) in states" :key="index" :value="state">{{ state }}</option>                                         
                                </select>
                            </div>
                            <div class="col-12 col-md-6">
                                <label for="" class="text-white d-block">Cidade</label>
                                <select id="where-to-find-city" :disabled="selectCityDisabled" class="d-block w-100 bg-white rounded-pill py-2 px-3 border-1 text-dark-gray">
                                    <option value="">Selecione</option>
                                    <option v-for="(city, index) in cities" :key="index" :value="city">{{ city }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12 col-md-6 offset-md-6">
                                <button type="button" @click="getCustomers" class="text-white bg-secondary text-center w-100 border-0 rounded-pill py-2 px-3 fz-small text-uppercase">Encontrar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    

        <div class="bg-light py-5">
            <div class="container">
                <div class="row">
                    <div class="col-6 col-md-3 d-flex mb-4" v-for="customer in customers" :key="customer.client_id">
                        <div class="border bg-white p-3 w-100">
                            <p class="text-secondary font-family-barlow fz-xmedium line-height-100 text-uppercase font-weight-bold mb-2">{{ customer.client_name }}</p>

                            <p class="text-primary font-family-barlow-regular fz-extra-small m-0">
                                {{ capitalizeFirstLetter(customer.client_address) }} <br>
                                {{ capitalizeFirstLetter(customer.client_neighborhood) }} - {{ capitalizeFirstLetter(customer.client_city) }}/{{ customer.client_state.toUpperCase() }}<br>
                                CEP: {{ customer.client_zip_code }}<br>
                                Telefone: {{ customer.client_phone }}<br>
                                E-mail: {{ customer.client_email.toLowerCase() }}<br>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Customer-With-Powertech-Products',
        props: {
            customerWithProducts: Object,
        },
        data() {
            return {
                states: [],    
                cities: [], 
                selectCityDisabled: true,
                customers: [],
                filters: {
                    state: '',
                    city: '',
                },           
            }
        },
        methods: {
            mountedData() {
                this.states = Object.keys(this.customerWithProducts);                
            },
            getCities() {                                
                const cities = this.customerWithProducts[this.filters.state].map(
                    customerWithProduct => customerWithProduct.client_city
                );    

                $('#where-to-find-city').select2('destroy').val('').select2({placeholder: 'Selecione a cidade'});

                this.cities = Array.from(new Set(cities)).sort();      
                
                this.selectCityDisabled = false;
            },
            getCustomers() {
                if (!this.filters.state || !this.filters.city) {
                    return;
                }

                let customers = this.customerWithProducts[this.filters.state];

                customers = customers.filter(customer => customer.client_city === this.filters.city);
                
                customers.sort((a, b) => a.client_name.localeCompare(b.client_name))

                this.customers = customers;
            },
            capitalizeFirstLetter(string) {
                return string.toLowerCase()
                        .split(' ')
                        .map(function(word) {
                            if (word !== '') {
                                return word[0].toUpperCase() + word.substr(1);
                            }
                        })
                        .join(' ');
            }
        },
        mounted() {
            const vm = this;
    
            $('#where-to-find-state').select2().on('change', function() {
                const state = $(this).val();

                if (!state || state === 'Selecione o estado') {
                    return;
                }

                vm.filters.state = state;
                vm.getCities();
            });
  
            $('#where-to-find-city').select2().on('change', function() {
                const city = $(this).val();

                if (!city || city === 'Selecione a cidade') {
                    return;
                }

                vm.filters.city = city;
            });

            this.mountedData();
        }
    };
</script>