<script>
    export default {
        name: 'atendimentosac',
        data() {
            return {
                data:{},
                city:[],
                classes: {},
                loading: false,
                success: false,
                error: false,
                defaults: {
                    'contact_area_id':'',
                    'name':'',
                    'email':'',
                    'phone':'',
                    'state':'',
                    'city':'',
                    'serial_number':'',
                    'reference':'',
                    'message':'',
                    'newsletter':0,
                    'privacy_policy': 0
                }
            };
        },

        mounted: function () {
            this.resetForm()
            var vm = this
            $('#state').on('change', function (ev, args) {
                if (!(args && "ignore" in args)) {
                    vm.data.state = $(this).val()
                    vm.getCities();
                }
            });

            $('#city').on('change', function (ev, args) {
                if (!(args && "ignore" in args)) {
                    vm.data.city = $(this).val()
                }
            });

            $('#contact_area_id').on('change', function (ev, args) {
                if (!(args && "ignore" in args)) {
                  vm.data.contact_area_id = $(this).val();
                  vm.changeLabel($(this), 'Selecione o produto', 'reference', 'productreference');
                  vm.changeLabel($(this), 'Número de série', 'serial_number', 'serialnumber');
                }
            });

            $('#reference').on('change', function (ev, args) {
                if (!(args && "ignore" in args)) {
                  vm.data.reference = $(this).val()
                }
            });
        },

        methods: {
            async getCities() {

                if(this.data.state == ''){
                    return
                }
                await this.axios.post(APP_URL + '/load-cities',{
                state: this.data.state
                }).then(function(response){
                    this.city = response.data.cities;

                }.bind(this));
            },

            async onSubmit (event) {
                if (this.loading){
                    return;
                }
                this.classes = {}
                this.loading = true;

                var formData = new FormData(this.$refs.form),
                    url = this.$refs.form.getAttribute("action");

                var vm = this
                vm.success = false
                vm.error = false
                await this.axios
                    .post(url, formData)
                    .then(response => {

                        if(response.data.success == true){
                            vm.success = true
                            vm.resetForm();
                        }else{
                            vm.error = true
                        }
                    })
                    .catch( error => {
                        vm.classes = error.response.data.errors || {}
                        vm.error = true
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },

          changeLabel(input, text, labelInput, dataAttribute) {

            let selectedOption = input.find('option:selected');
            let attribute = selectedOption.data(dataAttribute);
            var inputLabel = $('label[for="' +labelInput +'"]');

            if ( attribute == '1' ) {
              inputLabel.html(text + '<span class="text-secondary">*</span>')
            } else {
              inputLabel.html(text)
            }

          },
          resetForm() {
            this.data = Object.assign({}, this.defaults);
            $("#reference,#contact_area_id,#city,#state").val('').trigger('change')
          },
        },
    };
</script>