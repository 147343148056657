<script>
    import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
    import "flatpickr/dist/flatpickr.css";
    import Swal from "sweetalert2";

    export default {
        name:'Create-profile-clubepowertech',
        props: [
            'stateList',
            'productList'
        ],
        data() {
            return {
                data: {
                    isSection: 1,
                    documentLabelText:'CPF',
                    'mask': '###.###.###-##'
                },
                defaults: {
                    name:'',
                    email:'',
                    email_confirmation:'',
                    password:'',
                    password_confirmation:'',
                    person_type:'',
                    document:'',
                    postcode:'',
                    address:'',
                    address_number:'',
                    district:'',
                    city:'',
                    phone:'',
                    address_complement:'',
                    isSection: 1,
                    photo:'',
                    buy_date:'',
                    store:'',
                    fiscal_archive:'',
                    fiscal_number:'',
                    newsletter:'',
                    privacy_policy:'',
                    documentLabelText:'CPF',
                    'mask': '###.###.###-##'
                },
                products: this.productList,
                states: this.stateList,
                city:[],
                classes: {},
                loading: false,
                productCount: [''],
                flatConfig: {
                    locale: Portuguese,
                    dateFormat: 'd/m/Y',
                    "disable": [ this.disabledAfterToday ]
                },
                select2Settings: {
                    placeholder: {
                        id: 0, // the value of the option
                        text: 'Selecione'
                    },
                    templateSelection: function (data) {
                        if (data.id == '0' || data.id == '' ) { // adjust for custom placeholder values
                            return 'Selecione';
                        }

                        return data.text;
                    }
                },
                errorSerial: []
            };
        },
        mounted() {
            this.clearForm()
        },
        computed: {
            cepValue() {
                return this.data.postcode;
            },
        },
        watch: {
            cepValue(val) {
                this.getPostCode(val);
            }
        },
        methods: {
            changeLabelText(text){
                this.data.documentLabelText = text

                if(text == 'CPF'){
                    this.data.mask = '###.###.###-##';
                }else{
                    this.data.mask = '##.###.###/####-##';
                }
            },
            clearForm() {
                this.data = Object.assign({}, this.defaults);
                this.error = false
            },
            changeSection(e) {
                this.data.isSection = e
            },
            async getCities() {

                if(this.data.state == ''){
                    return;
                }

                await this.axios.post(APP_URL + '/load-cities',{
                state: this.data.state
                }).then(function(response){
                    this.city = response.data.cities;
                }.bind(this));
            },
            async getPostCode(value) {
                try {             
                    const CEP = value.replace(/[^\w\s]/gi, '');
                    
                    if (CEP.length !== 8) {
                        return false;
                    }

                    const response = await this.axios.get(`https://viacep.com.br/ws/${CEP}/json`, {
                        transformRequest: (data, headers) => {
                            delete headers.common['X-CSRF-Token']
                        }
                    });

                    if (response.data.erro) {
                        throw 'error';
                    }
                    
                    this.data.address  = response.data.logradouro;
                    this.data.district = response.data.bairro;
                    this.data.city     = response.data.localidade;
                    this.data.state    = response.data.uf; 
                    
                    this.selectState(this.data)
                    this.selectCity(this.data)
                } catch (error) {
                   console.log('Ocorreu um erro ao localizar');
                }
            },
            selectState(data) {
                const elemState = $('#state');
                let state = elemState.find("option:contains('" + data.state + "')").val();
                this.data.state = state;
            },
            async selectCity(data) {
              await this.getCities();
              const elemCity = $('#city');

              elemCity.find('option').each(function() {
                  const elemOption = $(this);

                  if (elemOption.text() === data.city) {
                      elemCity.val(elemOption.val()).trigger('change');

                      return false;
                  }
              });
            },
            async onSubmit (event) {
                if (this.loading){
                    return;
                }
                this.classes = {}
                this.loading = true;

                var formData = new FormData(this.$refs.form),
                    url = this.$refs.form.getAttribute("action");

                var vm = this

                await this.axios
                    .post(url, formData,{
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if(response.data.success) {
                            vm.data.isSection = response.data.section
                            if(response.data.section == 2 && response.data.success == true && response.data.redirect != false){
                                vm.clearForm()
                                Swal.fire({
                                    html: '<div><span class="modal-profile-title">Formulário enviado com sucesso!</span><span class="modal-profile-text">Enviamos um e-mail para você confirmar seu cadastro!</span></div>',
                                    type: 'success',
                                    confirmButtonColor: '#d63030',
                                    confirmButtonText: 'OK',
                                    width: 550,
                                    padding: '40px',
                                    customClass: {
                                        confirmButton: 'modal-profile-button'
                                    }
                                }).then(()=>{
                                    window.location.href = `${_ROOT_PATH}/clube-powertech/login`
                                });
                            }
                        } else {
                            vm.errorSerial = response.data.serial_numbers
                            if (response.data.message) {
                                Swal.fire({
                                    title: 'Oops!',
                                    text: response.data.message,
                                    type: 'error',
                                    confirmButtonColor: '#d63030',
                                    confirmButtonText: 'Ok!'
                                });
                            }
                        }
                    })
                    .catch( error => {
                        if(vm.data.isSection == 2 && (error.response.status !== 422 || !error.response.data.errors )) {
                            Swal.fire({
                                html: '<div><span class="modal-profile-title">Falha ao enviar o formulário!</span><span class="modal-profile-text">Tivemos um problema ao enviar o formulário, tente novamente!</span></div>',
                                type: 'error',
                                confirmButtonColor: '#d63030',
                                confirmButtonText: 'Ok',
                                width: 550,
                                padding: '40px',
                                customClass: {
                                    confirmButton: 'modal-profile-button'
                                }
                            });
                        }
                        vm.classes = error.response.data.errors || {}
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            disabledAfterToday(date) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return date > today
            },
            addProduct() {
                this.productCount.push('Novo' + this.productCount.length +1);
            },
            onChangeSelectCity({id, text}) {
                this.data.city = id;
            },
            onChangeSelectState({id, text}) {
                this.data.state = id;
                this.getCities();
            }
        },
    };
</script>