<template>
     <Modal-aviso title="SEJA BEM VINDO!" primarybuttontext="Ok" :primarybuttoncallback="closeModal" v-if="showModal">
        <div>
            <p>
                Aproveite os conteúdos exclusivos do Clube Powertech!
            </p>
            <p>
                Você ainda não viu o video que pode disponibilizar a garantia adicional para o seu produto!
            </p>
        </div>
    </Modal-aviso>
</template>
<script>
    export default {
        name: 'modal-seja-bem-vindo',
        data: function () {
            return {
                showModal: false,
            };
        },
        mounted() {
            setTimeout(() => {
                this.showModal = true;
            }, 2000);
        },
        methods: {
            closeModal: function () {
                this.showModal = false
                window.location.href = _ROOT_PATH + '/clube-powertech/meus-produtos'
            },
        }
    }
</script>