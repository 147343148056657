<template>
    <Modal-aviso 
        title="CARO USUÁRIO!"
        primarybuttontext="IR PARA MEUS PRODUTOS"
        :primarybuttoncallback="openModalVideo"
        v-if="showModal"
    > 
        <p>
          Cadastramos {{ pluralize('seu', 'seus') }}  {{ pluralize('produto', 'produtos') }}
          e percebemos que {{ pluralize('ele', 'eles') }} {{ pluralize('pode', 'podem') }} ganhar a garantia adicional!
        </p>
        <p class="text-blue">
            Para receber o benefício é muito fácil:
        </p>
        <p>
          <span>1</span> Visualize {{ pluralize('o', 'os') }} {{ pluralize('vídeo', 'vídeos') }}
          {{ pluralize('técnico', 'técnicos') }} {{ pluralize('do', 'dos') }}
          {{ pluralize('equipamento', 'equipamentos') }} na aba "Meus Produtos"
        </p>
        <p>
            <span>2</span> Aguarde a aprovação do cadastro pela Atlas.
        </p>
   </Modal-aviso>
</template>
<script>
   export default {
       name: 'modal-aviso-ver-video',
       props: {
         type: {
           type: String,
           default: 'padrao'
         },
         openModal: {
           default: false
         },
         plurals: {
           default: false,
         }

       },
       data: function () {
           return {
               showModal: false,
           };
       },
       watch: {
         openModal: function (val) {
           this.showModal = val
         },
       },
       methods: {
           closeModal: function () {
               this.showModal = false
           },
           openModalVideo: function () {
                this.showModal = false
                window.location.href = _ROOT_PATH + '/clube-powertech/meus-produtos'
           },
           pluralize( singulary, plularize) {
             return (this.plurals >0) ? plularize : singulary;
           }
       }
   }
</script>