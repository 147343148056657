<template>
    <div class="fz-small font-family-barlow">
        <div class="row">
            <div class="col-12 mb-5 col-lg-4 col-md-4 col-sm-12" :class="[classes.state?'error':'']">
                <label for="state" class="text-primary d-block font-weight-500">Estado</label>
                <select name="state" id="state" class="d-block w-100 bg-white rounded-pill py-2 px-3 border-1 text-dark-gray" v-model="filters.state">
                    <option v-for="(state, index) in states" :key="index" :value="state">{{ state }}</option>
                </select>
                <p v-if="classes.state" class="text-secondary mb-3 fz-extra-small"><i class="bi bi-exclamation-circle-fill"></i> Campos de preenchimento obrigatório</p>
            </div>
            <div class="col-12 mb-5 col-lg-4 col-md-4 col-sm-12" :class="[classes.city?'error':'']">
                <label for="city" class="text-primary d-block font-weight-500">Cidade</label>
                <select :disabled="selectCityDisabled" name="city" id="city" class="d-block w-100 bg-white rounded-pill py-2 px-3 border-1 text-dark-gray" v-model="filters.city">
                    <option v-for="(city, index) in cities" :key="index" :value="city">{{ city }}</option>
                </select>
                <p v-if="classes.city" class="text-secondary mb-3 fz-extra-small"><i class="bi bi-exclamation-circle-fill"></i> Campos de preenchimento obrigatório</p>
            </div>
            <div class="col-12 mb-5 col-md-3 offset-md-1 where-to-find-btn">
                <button @click="getCustomers" class="text-white bg-secondary text-center w-100 border-0 rounded-pill py-1 px-3 fz-medium">ENCONTRAR</button>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-4 col-md-6 col-sm-12 d-flex mb-4" v-for="customer in customers" :key="customer.client_id">
                <div class="border bg-white p-3 w-100">
                    <p class="text-secondary font-family-barlow fz-xmedium line-height-100 text-uppercase font-weight-bold mb-2">{{ customer.client_name }}</p>

                    <p class="text-primary font-family-barlow-regular fz-extra-small m-0">
                        {{ capitalizeFirstLetter(customer.client_address) }} <br>
                        {{ capitalizeFirstLetter(customer.client_neighborhood) }} - {{ capitalizeFirstLetter(customer.client_city) }}/{{ customer.client_state.toUpperCase() }}<br>
                        CEP: {{ customer.client_zip_code }}<br>
                        Telefone: {{ customer.client_phone }}<br>
                        E-mail: {{ customer.client_email.toLowerCase() }}<br>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'atendimentoondeencontrar',
        props: {
            customersWithPowertech: Object,
        },
        data() {
            return {
                filters: {},
                classes: {},
                customers: [],
                states: [],
                cities: [],
                selectCityDisabled: true,
                defaults: {
                    state: '',
                    city: '',
                }
            };
        },
        mounted: function () {
            this.states = Object.keys(this.customersWithPowertech)

            this.filters = Object.assign({}, this.defaults);

            const vm = this

            $('#state').on('change', function (ev, args) {
                if (!(args && "ignore" in args)) {
                    vm.filters.state = $(this).val()

                    if (!vm.filters.state || vm.filters.state == '') {
                        $('#state').select2('destroy').val('').select2({placeholder: 'Selecione o estado'})

                        this.selectCityDisabled = true;
                    }

                    this.cities = []

                    vm.getCities()
                }
            });

            $('#city').on('change', function (ev, args) {
                if (!(args && "ignore" in args)) {
                    vm.filters.city = $(this).val()
                }
            });
        },
        methods: {
            getCities() {
                $('#city').select2('destroy').val('').select2({placeholder: 'Selecione a cidade'});

                if (!this.customersWithPowertech[this.filters.state]) {
                    return
                }

                const cities = this.customersWithPowertech[this.filters.state].map(
                    customerWithPowertech => customerWithPowertech.client_city
                );

                this.cities = Array.from(new Set(cities)).sort();      
                
                this.selectCityDisabled = false;
            },
            getCustomers() {
                if (!this.filters.state || !this.filters.city) {
                    return;
                }

                let customers = this.customersWithPowertech[this.filters.state];

                customers = customers.filter(customer => customer.client_city === this.filters.city);
                
                customers.sort((a, b) => a.client_name.localeCompare(b.client_name))

                this.customers = customers;
            },
            capitalizeFirstLetter(string) {
                return string.toLowerCase()
                        .split(' ')
                        .map(function(word) {
                            if (word !== '') {
                                return word[0].toUpperCase() + word.substr(1);
                            }
                        })
                        .join(' ');
            }
        },
    };
</script>