<script>
    export default {
        name:'Contact-assistance',
        data() {
            return {
                data:{},
                city:[],
                success :false,
                error :false,
                classes: {},
                loading: false,
                defaults: {
                    'name':'',
                    'email':'',
                    'trade_name':'',
                    'document':'',
                    'postcode':'',
                    'state':'',
                    'city':'',
                    'phone':'',
                    'newsletter':0,
                    'privacy_policy': 0
                }
            };
        },

        mounted: function () {
            this.resetForm()
            var vm = this
            $('#state').on('change', function (ev, args) {
                if (!(args && "ignore" in args)) {
                    vm.data.state = $(this).val()
                    vm.getCities();
                }
            });

            $('#city').on('change', function (ev, args) {
                if (!(args && "ignore" in args)) {
                    vm.data.city = $(this).val()
                    vm.getCities()
                }
            });
        },
        computed: {
            cepValue() {
                return this.data.postcode;
            },
        },
        watch: {
            cepValue(val) {
                this.getPostCode(val);
            }
        },
        methods: {
            async getPostCode(value){

                var vm = this
                const CEP = value.replace(/[^\w\s]/gi, '');
                if (CEP.length === 8) {
                    this.axios.get('https://viacep.com.br/ws/'+CEP+'/json',
                        {
                            transformRequest: (data, headers) => {
                                delete headers.common['X-CSRF-Token']
                            }
                        }

                    )
                        .then(({data}) => {
                            if (data.cep) {
                                vm.data.address  = data.logradouro;
                                vm.data.district  = data.bairro;
                                vm.data.city = data.localidade;
                                vm.data.state = data.uf;

                                let valState = $('#state').find("option:contains('"+vm.data.state+"')").val()
                                $('#state').val(valState).trigger('change');

                                setTimeout(function() {
                                    let valCity = $('#city').find("option:contains('"+vm.data.city+"')").val()
                                    console.log(valCity)
                                    $('#city').val(valCity).trigger('change');
                                }, 2000);

                            }
                        })
                        .catch( error => {
                            console.log('Ocorreu um erro ao localizar')

                        })
                }
            },
            resetForm() {
                this.data = Object.assign({}, this.defaults);
            },
            async getCities() {

                await this.axios.post(APP_URL + '/load-cities',{
                state: this.data.state
                }).then(function(response){
                    this.city = response.data.cities;

                }.bind(this));
            },

            async onSubmit (event) {
                if (this.loading){
                    return;
                }
                this.classes = {}
                this.loading = true;

                var formData = new FormData(this.$refs.form),
                    url = this.$refs.form.getAttribute("action");

                var vm = this
                vm.success = false
                vm.error = false
                await this.axios
                    .post(url, formData)
                    .then(response => {
                if(response.data.success == true)
                {
                    vm.resetForm();
                    vm.success = true
                }
                    })
                    .catch( error => {
                        vm.classes = error.response.data.errors || {}
                        vm.error = true
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
        },
    };
</script>