<template>
    <div class="shadow">
        <div class="modal-background p-md-4">
            <div class="video-container">
                <vimeo-player v-if="this.videoType == 'vimeo'" :vimeoId="videoUrl" @watched="handleWatched"/>
                <youtube-player v-else-if="this.videoType == 'youtube'" :videoUrl="videoUrl" @watched="handleWatched" />
            </div>
            <p class="fz-medium w-100 px-2">Confira o vídeo técnico de {{ this.title }}</p>
            
            
            <button v-if="canClose && !error" @click="closeModal" class="text-white bg-secondary text-center border-0 rounded-pill py-1 px-5 my-3 fz-medium text-uppercase btn btn-secondary">Ok</button>
            <p v-if="error" class="fz-small w-100 m-0 text-center error-message">Erro na validação, tente novamente.</p>
            <div v-if="error" class="d-flex">
                <button v-if="canClose" @click="closeModal" class="text-white bg-secondary text-center border-0 rounded-pill py-1 px-5 my-3 fz-medium text-uppercase btn btn-secondary mr-lg-2">Fechar</button>
                <button v-if="canClose" @click="handleWatched" class="text-white bg-secondary text-center border-0 rounded-pill py-1 px-5 my-3 fz-medium text-uppercase btn btn-secondary">Tentar novamente</button>
            </div>
            <vue-element-loading :active="!canClose" spinner="bar-fade-scale"/>
        </div>
    </div>
</template>
<script>
import VimeoPlayer from './Vimeo-Player.vue';
import YoutubePlayer from './Youtube-Player.vue';
    export default {
        name: 'Modal-video-garantia',
        components: {
            VimeoPlayer,
            YoutubePlayer
        },
        props: {
            title: {
                type: String,
                default: ''
            },
            video: {
                type: String,
                default: ''
            },
            videoType: {
                type: String,
                default: ''
            },
            video_id: {
                type: String,
                default: ''
            },
            product_id: {
                type: String,
                default: ''
            },
            user_id: {
                type: String,
                default: ''
            },
        },
        data: function () {
            return {
                videoUrl: this.video,
                watched: false,
                canClose: true,
                error: false,
                approved: false,
                canceled: false,
                expired: false,
            };
        },
        methods: {
            closeModal: function () {
                this.$emit('closemodal')
                if (!this.watched) {
                    window.location.reload();
                }

                if(this.watched){
                  if(this.canceled === true) {
                      window.location.reload();
                    return;
                  }

                  if(this.expired === true) {
                    this.$emit('showmodalexpired')
                    return;
                  }

                  this.$emit('showmodalcongrats', this.approved)
                }
            },
            async handleWatched() {
                const params = {
                    video_id: this.video_id,
                    product_id: this.product_id,
                    user_id: this.user_id,
                    watched: 1
                }

                try{
                    this.canClose = false;
                    const response = await this.axios.post(APP_URL + '/clube-powertech/mark-video-as-viewed', params)
                    if(response.data.success){
                        this.watched = true;
                        this.approved = response.data.approved;
                        this.canceled = response.data.canceled;
                        this.expired = response.data.expired;
                        this.error = false;
                    }
                }
                catch(error){
                    this.error = true;
                }
                finally {
                    this.canClose = true;
                }
            }
        },
    }
</script>
<style scoped lang="scss">
.error-message{
    color: red;
    font-weight: 500;
}
.shadow{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-background{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    max-width: 700px;
    background-color: #fff;
    flex-direction: column;
    @media screen and (max-width: 600px) {
        width: 100%;
    }
}
.video-container{
    width: 100%;
}
</style>