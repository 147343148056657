<template>
     <Modal-aviso title="PARABÉNS!" primarybuttontext="IR PARA MEUS PRODUTOS" :primarybuttoncallback="closeModal" v-if="showModal"> 
        <p>
            O seu cadastro foi aprovado para ganhar a garantia adicional!
        </p>
        <p>
            Agora é só visualizar o vídeo técnico do equipamento para ganhar o benefiio de garanria adicional!
        </p>
    </Modal-aviso>
</template>
<script>
    export default {
        name: 'modal-aviso-concluido-redirect',
        data: function () {
            return {
                showModal: false,
            };
        }, 
        methods: {
            closeModal: function () {
                this.showModal = false;
                window.location.href = _ROOT_PATH + '/clube-powertech/meus-produtos'
            },
        }
    }
</script>