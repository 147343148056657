import Vue from 'vue'
import Vuex from 'vuex'
import modalNewsletterPreferences from './modules/modalNewsletterPreferences'

Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
        modalNewsletterPreferences

    }
})